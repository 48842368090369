.App {
  text-align: center;
}

.loader {
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #005daa;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.sold-out-banner {
  height: 50px;
  background-color: #ab0c07;
  width: 90%;
  display: flex;
  justify-content: center;
  margin: auto;
  z-index: 10 !important;
}

.tickets-banner {
  height: 50px;
  background-color: #005daa;
  width: 90%;
  display: flex;
  justify-content: center;
  margin: auto;
  z-index: 10 !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Style the image inside the centered container, if needed */
.centered img {
  width: 150px;
  border-radius: 50%;
}

html {
  background-color: white; 
  text-align: center;
  font-family: 'Open Sans Condensed',Helvetica,Arial,sans-serif;
  font-size: 13px;
  font-weight: 700;
}

h3 {
  font-size: 40px;
  font-weight: bold;
  color: #006699;
  padding: 30px;
  padding-bottom: 0px;
  margin: auto;
  text-align: center;
  font-family: 'Open Sans Condensed',Helvetica,Arial,sans-serif;
}

.raffle_card {
  z-index: 10 !important;
  color: white !important;
  font-size: 16px !important;
  background-color: #005daa !important;
}

.raffle_card:hover {
  color: white !important;
  background-color: #17458f !important;
}

.inactive-tab-button {
  color: white;
  font-size: 30px;
  text-Decoration: none;
  padding: 20px;
}

.inactive-tab-button-login {
  color: black;
  font-size: 22px;
  text-Decoration: none;
  padding: 20px;
}

.inactive-tab-button:hover {
  color: #f7a81b;
  font-size: 30px;
  text-decoration: none;
  padding: 20px;
}

.inactive-tab-button-login:hover {
  color: #f7a81b;
  font-size: 22px;
  text-decoration: none;
  padding: 20px;
}

.active-tab-button {
  color: #f5c31d;
  font-size: 30px;
  text-decoration: none;
  padding: 20px;
}

p {
  line-height: 100%;
  color: black;
  font-size: 18px;
  font-weight: lighter;
  line-height: 1.42857143;
  font-family: Georgia,'Times New Roman',Times,serif;
}

li {
  line-height: 100%;
  color: black;
  font-size: 20px;
}

.raffle_title {
  font-size: 36px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

.raffle_card_description {
  font-size: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabHeader {
  border-top: 40px solid #17458f;
  font-family: 'Open Sans Condensed',Helvetica,Arial,sans-serif;
  min-height: 75px;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 5000;
  background-color: #005daa;
}

.footer {
  background-color: #005daa;
  padding-top: 10px;
  margin-top: 100px;
  left: 0;
  width: 100%;
  min-width: 100%;
  z-index: 5000;
  position: absolute;
  justify-content: center;
}

.socalIcons {
  background-color: rgb(44, 43, 43) !important;
  height: 100px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  width: 200px;
  border: 2px solid #005daa;
  border-top: none;
  padding: 2px 2px;
  z-index: 1;
  border-radius: 10px;
}

.dropdown:hover {
  color:#006699;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.menu {
  width: 50px;
  border: 1px solid lightgrey;
  position: relative;
  display: block;
}

input {
    width: 50%;
    padding: 12px;
    border: 1px solid grey;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

textarea {
    width: 50%;
    height: 120px;
     padding: 12px;
     border: 1px solid grey;
     border-radius: 4px;
     box-sizing: border-box;
     margin-top: 6px;
     margin-bottom: 16px;
     resize: vertical;
}


.raffle-button {
  min-width: 64px;
  font-size: 22px;
  font-weight: bold;
  color: white;
  background-color: #005daa;
  border: none;
  padding: 10px;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  margin: 20px;
  font-size: 20px;
  transition: all 0.2s;
}

.raffle-button:hover {
  color:white;
  background-color: #17458f;
  transition: all 0.2s;
}

.raffle-button-disabled {
  min-width: 64px;
  font-size: 22px;
  font-weight: bold;
  color: white;
  background-color: grey;
  border: none;
  padding: 10px;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  margin: 20px;
  font-size: 20px;
  transition: all 0.2s;
  opacity: 60%;
}

.add-button-icon {
  color: #059934;
  transition: all 0.2s;
}

.add-button-icon:hover {
  color: #07ab3b;
  transition: all 0.2s;
}

.print-button-icon {
  font-size: 40px !important;
  color: #005daa;
  margin-left: 15px;
  transition: all 0.2s;
}

.print-button-icon:hover {
  color: #17458f;
  transition: all 0.2s;
}

.cancel-button  {
  min-width: 64px;
  font-size: 22px;
  font-weight: bold;
  color: white;
  background-color: #ab0c07;
  border: none;
  padding: 10px;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  margin: 20px;
  font-size: 20px;
  transition: all 0.2s;
}

.cancel-button:hover {
  color:white;
  background-color: #bd0d08;
  transition: all 0.2s;
}

.errorLabel {
  color:  #ab0c07;
}

.submit-button {
  min-width: 64px;
  color: white !important;
  font-size: 16px !important;
  transition: all 0.2s;
  background-color: #005daa !important;
}

.submit-button:hover {
  color: white !important;
  transition: all 0.2s;
  background-color: #17458f !important;
}

.delete-button-icon {
  margin-left: 20px;
  color: #ab0c07;
  transition: all 0.2s;
}

.delete-button-icon:hover {
  color: #bd0d08;
  transition: all 0.2s;
}

.input-item p {
  text-align: center;
  margin-right: 15px;
  height: 50px;
  width: 240px;
  float: left;
}

.input-item-field {
  width: 250px;
  height: 50px;
  z-index: 10 !important;
}

.input-item-field-checkbox {
  width: 50px;
  height: 50px;
  z-index: 10 !important;
}

.input-item {
  display: inline-block;
  z-index: 200;
}

label {
  font-size: 30px;
  color: black;
  margin: 20px;
  min-height: 30px;
  min-width: 100px;
}

.tabContent {
  font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;
  text-align: center;
  max-width: 80%;
  height: 100%;
  padding: 20px;
  padding-top: 150px;
  border: none;
  margin: auto;
  margin-bottom: -20px;
  border-top: none;
  background-color: white;
}

.payment-stripe-div {
  width: 400px;
  margin: auto;
}

.hamburgerMenu {
  margin-right: 20px;
  margin-top: 10px;
  float: right;
}

.container {
  padding: 15px;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('Fonts/OpenSansCondensed-Light.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
} 

.MuiTooltip-tooltip {
  font-size: 22px !important;
}